import React, { useState, useEffect } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../components/table/table";
import "./view.css";
import CSVExport from "../../../components/csvExport";
import {
  get_manPowerList_list,
  admin_expman_delete,
  get_candidates_list,
  get_companyProfile_list,
  admin_candidates_list,
  admin_downloaded_candidates_list,
} from "../admin.api";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom";
export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const param = useParams();
  const onSearch = () => {
    //
  };
  const handleAdviserList = () => {
    setIsActive(true);
    admin_downloaded_candidates_list(param.id).then((res) => {
      let tdata = res.data;
      let searchData = [];
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
        // searchData.push(data[0]?.);
      });
      setdata(tdata);
      setIsActive(false);
    });
  };
  const handleAddNew = (row) => {
    let path = `/manpower-add`;
    props.history.push(path);
  };

  const handleView = (data) => {
    let path = `/profile-view/${data?.candidate_details?.id}/${param?.id}`;
    props.history.push(path);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_expman_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleAdviserList();
    }
  };

  const handleDownload = () => {};
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  useEffect(() => {
    handleAdviserList();
  }, []);
  async function fetchExportData() {
    const exportdata = [];
    data.forEach((item) => {
      exportdata.push({
        ["Name"]: `${item?.prefix},${item?.name}`,
        [`Status`]: `${
          item.downloaded_profile_status === 1
            ? "Interview Scheduled"
            : item.downloaded_profile_status === 2
            ? "Appointment Confirmed"
            : item.downloaded_profile_status === 3
            ? "Joined Successfully"
            : item.downloaded_profile_status === 0
            ? "Downloaded"
            : item.downloaded_profile_status === 4
            ? "Paid"
            : ""
        }`,
      });
    });
    return exportdata;
  }
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        {/* <h6>
          <b>Expert Manpower</b>
        </h6> */}
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            <b>Downloaded Profiles</b>
          </h6>
          <CSVExport title="Companies List" fetchData={fetchExportData} />
        </div>
        <hr />
        {/* ---------admin--------------- */}
        {adminType === 1 && (
          <>
            {/* <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button> */}
            {/* <div className="row font-z mt-2">
              <div className="col-3 ">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Application Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                </select>
              </div>
              <div className="col-1 d-flex">
                <SearchButton {...props} onClick={onSearch} />
              </div>
            </div> */}
            <CustomTable
              {...props}
              columns={[
                { title: " Name", field: "name", type: "AdminDownload" },
                { title: " Email", field: "email", type: "AdminDownload" },
                { title: " Contact", field: "contact", type: "AdminDownload" },
                {
                  title: "Status",
                  field: "downloaded_profile_status",
                  type: "downloadedStatus",
                },

                // { title: "Area of Expertise", field: "category" },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
        {adminType === 2 && (
          <>
            {/* <div className="row font-z"> */}
            {/* <div className="col-3 ">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Application Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                </select>
              </div>
              <div className="col-1 d-flex">
                <SearchButton {...props} onClick={onSearch} />
              </div>
            </div> */}
            <CustomTable
              {...props}
              columns={[
                { title: " Name", field: "name", type: "AdminDownload" },
                { title: " Email", field: "email", type: "AdminDownload" },
                { title: " Contact", field: "contact", type: "AdminDownload" },
                {
                  title: "Status",
                  field: "downloaded_profile_status",
                  type: "downloadedStatus",
                },

                // { title: "Area of Expertise", field: "category" },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Job Seeker ?"
          data={activeData}
          message="Are you sure to delete this Job Seeker?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
