import { Button } from "bootstrap";
import React from "react";
import { useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import "../style.css";

export default function View(props) {
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  console.log(props.basicData);
  const [agree, setAgree] = useState(false);
  const handleCheckChange = (e) => {
    let checked = e.target.checked;
    console.log(checked);
    setAgree(checked);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.action(2);
  };
  console.log(props.basicData?.work_experience?.file_name);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="view-container">
          <h6>
            <b>Basic Details</b>
          </h6>

          <hr />

          <div className="data">
            <div className="row">
              <div className="col-3 field-titles">
                Are you a native of Kerala?
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.native_kerala == 1 ? "Yes" : "No" ?? ""}
              </div>
            </div>
            {props.basicData?.native_kerala == 0 && (
              <div className="row">
                <div className="col-3 field-titles">
                  Are you a Non Resident Keralite?
                </div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">Yes</div>
              </div>
            )}
            <div className="row">
              <div className="col-3 field-titles">Type of Identity Proof</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.identity_type}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Identity Number</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.identity_no}</div>
            </div>
            {/* <div className="row">
              <div className="col-3 field-titles">Identity File</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.file_name}</div>
            </div> */}
            {props.update === true &&
            typeof props.basicData?.identity_file == "string" ? (
              <div className="row">
                <div className="col-3 field-titles">Identity Proof</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(
                        `${props.basicData?.identity_file}`,
                        "_blank"
                      );
                    }}
                    className="detailFile"
                  >
                    View
                  </a>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">Identity File </div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{props.basicData?.file_name ?? ""}</div>
              </div>
            )}
           
            {props.basicData?.native_address_type && (
              <div className="row">
                <div className="col-3 field-titles">
                  Proof of Native Address
                </div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  {props.basicData?.native_address_type ?? ""}
                </div>
              </div>
            )}
            {props.basicData?.non_keralite_file && (
              <>
                {props.update === true &&
                typeof props.basicData?.non_keralite_file == "string" ? (
                  <div className="row">
                    <div className="col-3 field-titles">
                      Upload Proof of Native Address
                    </div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      <a
                        onClick={() => {
                          window.open(
                            `${props.basicData?.non_keralite_file}`,
                            "_blank"
                          );
                        }}
                        className="detailFile"
                      >
                        View
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-3 field-titles">
                      Upload Proof of Native Address
                    </div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData?.nri_name ?? ""}
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="row">
              <div className="col-3 field-titles">Are you a fresher?</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.fresher ?? ""}</div>
            </div>

            <div className="row">
              <div className="col-3 field-titles">Name (as per Aadhaar)</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData.prefix ? props.basicData.prefix + "." : "Mr."}{" "}
                {props.basicData.fname ?? ""}{" "}
                {/* {props.basicData.last_name ?? ""} */}
              </div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Date of Birth</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.dob ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Gender</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.gender ?? "--"}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Marital Status</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.marital_status ?? ""}
              </div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Father / Spouse Name</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.parent_name ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Category</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.caste ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">
                Are you Differently Abled?
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.handicapped ?? ""}</div>
            </div>
            {/* <div className="row">
              <div className="col-3 field-titles">Are you physically hadicapped?</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.hadicapped ?? ""}</div>
            </div> */}
            {props?.basicData?.handicapped_file && (
              <>
                {props.update === true &&
                typeof props.basicData?.handicapped_file == "string" ? (
                  <div className="row">
                    <div className="col-3 field-titles">
                      Disability Certificate
                    </div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      <a
                        onClick={() => {
                          window.open(
                            `${props.basicData?.handicapped_file}`,
                            "_blank"
                          );
                        }}
                        className="detailFile"
                      >
                        View
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-3 field-titles">
                      Disability Certificate
                    </div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData?.handi_name ?? ""}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="row">
              <div className="col-3 field-titles">Address</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.address}</div>
            </div>

            <div className="row">
              <div className="col-3 field-titles">Email Id</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.email ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Phone Number</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">+91 {props.basicData?.mobile ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Alternate Email Id</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.alt_email ?? ""}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">
                {props.basicData?.native_kerala != 1
                  ? "NRI Phone Number"
                  : "Alternate Phone"}
              </div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData.alt_code ? "+" : ""}
                {props.basicData.alt_code ?? ""}{" "}
                {props.basicData.alt_contact ?? ""}
              </div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Pin Code</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.pin_code}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Blood Group</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.blood_group == "Other"
                  ? props.basicData?.other_blood_group
                  : props.basicData?.blood_group}
              </div>
            </div>
            {/* {props?.basicData?.blood_group === "Other" &&
          <div className="row">
          <div className="col-3 field-titles">Specify Blood Group</div>
          <div className="col-1 hideonMobile">:</div>
          <div className="col-7">{props.basicData?.other_blood_group}</div>
        </div>
          } */}

            {props.update === true &&
            typeof props.basicData?.photo == "string" ? (
              <div className="row">
                <div className="col-3 field-titles">Photo</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(`${props.basicData?.photo}`, "_blank");
                    }}
                    className="detailFile"
                  >
                    View
                  </a>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">Photo</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{props.basicData?.photo_name ?? ""}</div>
              </div>
            )}
            {props.update === true &&
            typeof props.basicData?.cv_upload == "string" ? (
              <div className="row">
                <div className="col-3 field-titles">CV File</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">
                  <a
                    onClick={() => {
                      window.open(`${props.basicData?.cv_upload}`, "_blank");
                    }}
                    className="detailFile"
                  >
                    View
                  </a>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-3 field-titles">CV File</div>
                <div className="col-1 hideonMobile">:</div>
                <div className="col-7">{props.basicData?.cv_name ?? ""}</div>
              </div>
            )}
            {/* <div className="row">
              <div className="col-3 field-titles">Highest Qualification</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.qualification_type}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Qualification</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.qualification}</div>
            </div> */}
          </div>
          <h6>
            <b>Educational Qualifications</b>
          </h6>

          <hr />

          <div className=" mobix data">
            <div className="row">
              {props.basicData?.education?.map((item) => (
                <div className="data-cards">
                  <div className="col-12 mb-1">
                    <div className="row">
                      <div className="col-3 field-titles">
                        Qualification Type
                      </div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item?.edu_type_parent}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Qualification</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item?.edu_type_sub_one}</div>
                    </div>
                    {item?.edu_type_sub_one !== "PHD" && (
                      <div className="row">
                        <div className="col-3 field-titles">Stream</div>
                        <div className="col-1 hideonMobile">:</div>
                        <div className="col-7">
                          {item?.edu_type_sub_two &&
                          item?.edu_type_sub_two !== "Others"
                            ? item?.edu_type_sub_two
                            : item?.edu_type_others_text}
                        </div>
                      </div>
                    )}
                    {item?.edu_type_sub_one === "PHD" && (
                      <div className="row">
                        <div className="col-3 field-titles">Thesis</div>
                        <div className="col-1 hideonMobile">:</div>
                        <div className="col-7">
                          {item?.edu_type_others_text}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-3 field-titles">Board/University</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.university}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Location</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.location}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">Year of Passing</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.year}</div>
                    </div>
                    {/* <div className="row">
                      <div className="col-3 field-titles">Grade</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.grade}</div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">GPA</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.gpa}</div>
                    </div> */}
                    <div className="row">
                      <div className="col-3 field-titles">
                        {item?.edu_qualification_type}
                      </div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">
                        {item?.edu_qualification_text}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 field-titles">
                        {" "}
                        Equivalent Percentage
                      </div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item?.percentage}</div>
                    </div>
                    {props.update === true &&
                    typeof item?.edu_certificate == "string" ? (
                      <div className="row">
                        <div className="col-3 field-titles">
                          Uploaded Certificate
                        </div>
                        <div className="col-1 hideonMobile">:</div>
                        <div className="col-7">
                          <a
                            onClick={() => {
                              window.open(`${item?.edu_certificate}`, "_blank");
                            }}
                            className="detailFile"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-3 field-titles">
                          Uploaded Certificate
                        </div>
                        <div className="col-1 hideonMobile">:</div>
                        <div className="col-7">{item.edu_cert_name ?? ""}</div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {props?.basicData?.fresher === "No" && (
            <>
              <h6>
                <b>Employment Details</b>
              </h6>

              <hr />
              <div className="data">
                <div className="row">
                  {props.basicData?.employment?.map((item) => (
                    <div className="mobix data-cards">
                      <div className="col-12 mb-1">
                        <div className="row">
                          <div className="col-3 field-titles">
                            Name of Organization
                          </div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">{item.employer}</div>
                        </div>
                        <div className="row">
                          <div className="col-3 field-titles">Contact No</div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">{item.contact}</div>
                        </div>
                        <div className="row">
                          <div className="col-3 field-titles">Email Id</div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">{item.email}</div>
                        </div>
                        <div className="row">
                          <div className="col-3 field-titles">Position</div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">{item.position}</div>
                        </div>
                        <div className="row">
                          <div className="col-3 field-titles">
                            Are you Currently Working?
                          </div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">
                            {item.working === true ? "Yes" : "No"}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3 field-titles">
                            {item.working == true ? "Joining Date" : "Period"}
                          </div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">
                            {item.from}{" "}
                            {item.working !== true ? <>to {item?.to}</> :  " (till now)"}
                          </div>
                        </div>
                        {/* <div className="row">
                      <div className="col-3 field-titles">Designation</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.designation}</div>
                    </div> */}
                        {item.working !== true && (
                          <div className="row">
                            <div className="col-3 field-titles">
                              Reason for Leaving
                            </div>
                            <div className="col-1 hideonMobile">:</div>
                            <div className="col-7">{item.reason}</div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-3 field-titles">Address</div>
                          <div className="col-1 hideonMobile">:</div>
                          <div className="col-7">{item.address}</div>
                        </div>
                        {/* <div className="row">
                      <div className="col-3 field-titles">Uploaded File</div>
                      <div className="col-1 hideonMobile">:</div>
                      <div className="col-7">{item.file_name}</div>
                    </div> */}
                        {props.update === true &&
                        typeof item?.exp_certificate == "string" ? (
                          <div className="row">
                            <div className="col-3 field-titles">
                              {item?.working === false
                                ? "Experience certificate ("
                                : "Appointment Order"}
                            </div>
                            <div className="col-1 hideonMobile">:</div>
                            <div className="col-7">
                              <a
                                onClick={() => {
                                  window.open(
                                    `${item?.exp_certificate}`,
                                    "_blank"
                                  );
                                }}
                                className="detailFile"
                              >
                                View
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-3 field-titles">
                              {item?.working === false
                                ? "Experience certificate"
                                : "Appointment Order"}
                            </div>
                            <div className="col-1 hideonMobile">:</div>
                            <div className="col-7">{item.file_name ?? ""}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <h6>
            <b>Reference 1</b>
          </h6>

          <hr />
          <div className=" mobix data">
            <div className="row">
              <div className="data-cards">
                <div className="col-12 mb-1">
                  <div className="row">
                    <div className="col-3 field-titles">Name</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_name1}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Designation</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_designation1}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Address</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_address1}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Contact No</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_contact1}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Email Id</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_email1}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6>
            <b>Reference 2</b>
          </h6>

          <hr />
          <div className=" mobix data">
            <div className="row">
              <div className="data-cards">
                <div className="col-12 mb-1">
                  <div className="row">
                    <div className="col-3 field-titles">Name</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_name2}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Designation</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_designation2}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Address</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_address2}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Contact No</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_contact2}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 field-titles">Email Id</div>
                    <div className="col-1 hideonMobile">:</div>
                    <div className="col-7">
                      {props.basicData.reference_email2}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="data">
            <div className="row">
              <div className="col-3 field-titles">Area of Interest</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">{props.basicData?.interest_type}</div>
            </div>
            <div className="row">
              <div className="col-3 field-titles">Preferences</div>
              <div className="col-1 hideonMobile">:</div>
              <div className="col-7">
                {props.basicData?.expertise_area.map(
                  (item, i) => item?.checked && <div>{item?.domain_name}</div>
                )}
                {props.basicData?.any_other_flag && (
                  <div>{props?.basicData?.any_other_domain}</div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex pt-3">
            <div>
              <center>
                <CommonForm
                  fieldType="checkbox"
                  value={agree}
                  required
                  checked={agree}
                  onChange={(e) => handleCheckChange(e)}
                  label=""
                  {...props}
                />
              </center>
            </div>
            <div>
              I hereby declare that the details furnished above are true and
              correct to the best of my knowledge and belief. In case any of the
              above information is found to be false or untrue or misleading or
              misrepresenting, I am aware that I may be held liable for it.
            </div>
          </div>

          <div className="pt-3">
            <b style={{ fontSize: "18px" }}>
              Employers may directly contact you if they are interested in your
              profile. Any queries/requests to KSPACE are not entertained.
            </b>
          </div>
          <div className="footer-buttons mt-3">
            <div
              className="secondary-btn"
              onClick={(e) => {
                props.action(1);
              }}
            >
              GO BACK
            </div>

            <button className="primary-btn" type="submit">
              REGISTER
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
