import React, { useEffect, useState } from "react";
import Loader from "../../../../components/loader/loader";
import CustomTable from "../../../../components/table/table";
import ManageTender from "./manage/manage";
import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import Snackbar from "../../../../components/snackbar/snackbar";
import { admin_tender_list, admin_tender_delete } from "../../admin.api";
import "./view.css";
import jwtDecode from "jwt-decode";
import moment from "moment";
export default function View(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [data, setdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [view, setView] = useState(false);

  const [isActive, setIsActive] = useState(true);
  const [fileError, setFileError] = useState(false);
  const handleEventList = () => {
    setIsActive(true);
    admin_tender_list().then((res) => {
      let tempData = res.data;

      if (tempData) {
        tempData.forEach((item, index) =>  {
          console.log(item);
          item["slno"] = index + 1;
          if (item?.type && item?.type === "public") {
            item["public"] = 1;
          } else if (item?.type && item?.type === "private") {
            item["public"] = 0;
          } 
           if (item?.due_date) {
            item["due_date"] = moment(item?.due_date, "YYYY-MM-DD HH:mm").format(
              "DD-MM-YYYY HH:mm"
            );
          }
        });
        console.log(tempData);
        setdata(tempData);
      }
      setdata(res.data);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  const handleAddNew = () => {
    const a = {
      public: 0,
      type: "private",
    };
    setFormData(a);
    setAddNew(true);
    setView(false);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    admin_tender_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setView(true);
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Tender</b>
        </h6>
        <hr />
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleAddNew(e)}
        >
          Add New Tender
        </button>
        <CustomTable
          {...props}
          columns={[
            { title: " SL No", type: "dynId" },
            { title: " Tender Title", field: "tender_title" },
            { title: " Tender Description", field: "description" },
            { title: "Tender by", field: "organized_by" },
            { title: "Due Date", field: "due_date" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                ]
          }
        />
      </div>
      {addNew && (
        <ManageTender
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
          fileError={fileError}
          setFileError={setFileError}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Tender ?"
          data={activeData}
          message="Are you sure to delete this Tender?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
