import React, { useState, useEffect } from "react";
import TextField from "../../../components/inputs/textfield";
import ResourseTile from "../../../components/page/tiles/resourse";
import "./style.css";
import {
  candidateList,
  expertManPowerList,
  qualifications_list,
} from "../user.api";
import Loader from "../../../components/loader/loader";
import { Toast } from "bootstrap";
import SearchButton from "../../../components/buttons/search/search-btn";
import ResourseTileCard from "../../../components/page/tiles/resourseCards";
let count = 100;
const percent = [
  {
    name: "40 Above",
    value : 40
  },
  {
    name: "50 Above",
    value : 50
  },
  {
    name: "60 Above",
    value : 60
  },
  {
    name: "70 Above",
    value : 70
  },
  {
    name: "80 Above",
    value : 80
  },
  {
    name: "90 Above",
    value : 90
  },
];
export default function ExpertManPowerList(props) {
  const [isActive, setIsActive] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [formData, setFormData] = useState({});
  const [qual, setQual] = useState([]);
  const [qualSub, setQualSub] = useState([]);
  const [qualStream, setQualStream] = useState([]);
  const [setValue , setSetValue] = useState([])
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const param = {
    ...(formData?.edu_type_parent
      ? { edu_type_parent: formData?.edu_type_parent }
      : {}),
    ...(formData?.edu_type_sub_one
      ? { edu_type_sub_one: formData?.edu_type_sub_one }
      : {}),
    ...(formData?.edu_type_sub_two
      ? { edu_type_sub_two: formData?.edu_type_sub_two }
      : {}),
    ...(formData?.percentage ? { percentage: formData?.percentage } : {}),
  };
  const listData = (param) => {
    // const param = {
    //   edu_type_parent : formData?.edu_type_parent ?? "",
    //   edu_type_sub_one : formData?.edu_type_sub_one ?? "",
    //   edu_type_sub_two : formData?.edu_type_sub_two ?? "",
    //   percentage : formData?.percentage
    // }

    candidateList(param).then((res) => {
      setSetValue(param?.edu_type_sub_one ?? "")
      let dir = res.data;
      setIsActive(false);
      let finalData = [];
      // dir.forEach((item) => {
      //   if (
      //     item.expert_manpower?.is_resource_person === 0 &&
      //     item.expert_manpower?.admin_status === 4
      //   ) {
      //     finalData.push(item);
      //   }
      // });

      setSearchData(dir);

      setIsActive(false);
    }).catch((err) => {
      setSetValue("")
    });
  };
  const [data, setData] = useState([]);
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };
    if (e.target.name === "edu_type_parent") {
      const sub = qual.find((item) => item.name === e.target.value);
      setQualSub(sub?.children_recursive);
      data = {
        ...data,
        [e.target.name]: e.target.value,
        edu_type_sub_one: "",
        edu_type_sub_two : "",
        percentage: "",
      };
    } else if (e.target.name === "edu_type_sub_one") {
      const stream = qualSub.find((item) => item.name === e.target.value);
      setQualStream(stream?.children_recursive);
      const otherId = qualSub.filter((item) => item.name === e.target.value)[0]
        .id;
      console.log(otherId);
      data = {
        ...data,
        [e.target.name]: e.target.value,
        edu_type_sub_other_id: otherId,
      };
    } else if (e.target.name === "edu_type_sub_two") {
      data = {
        ...data,
        [e.target.name]: e.target.value,
      };
    } else {
      data = {
        ...data,
        [e.target.name]: e.target.value,
      };
    }

    setFormData(data);
  };
  useEffect(() => {
    listData();
  }, []);
  useEffect(() => {
    qualifications_list()
      .then((res) => {
        setQual(res.data);
      })
      .catch((err) => {
        setQual([]);
        Toast.error("Something Went Wrong");
      });
  }, []);
  return (
    <div className="tender bg-tender">
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Jobseeker Profiles</div>
        </div>
      </div>
      <div className="container p-50">
        <div className="res-page-cont">
          <div className="row tender-box">
            {/* <div className="col-xs-12 col-sm-3">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input type="text" className="form-control" placeholder="Search" />
              </div>
            </div> */}
            <div className="row">
              <div className="col-3">
                <div className="col-xs-12 col-sm-12">
                  <div className="header-label mb-1">Qualification Type</div>
                  <select
                    className="form-select selectbox mandatory-select "
                    required
                    name="edu_type_parent"
                    value={formData?.edu_type_parent ?? ""}
                    aria-label="Default select example"
                    // onChange={(e) => selectFieldChange(e)}
                    onChange={(e) => {
                      handleEducationalChange(e);
                    }}
                  >
                    <option value="">-- Select--</option>
                    {qual?.map((item, i) => (
                      <option value={item.name} key={i}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {formData?.edu_type_parent && qualSub && qualSub.length !== 0 && (
                <div className="col-3">
                  <div className="col-xs-12 col-sm-12">
                    <div className="header-label mb-1">Qualification</div>
                    <select
                      className="form-select selectbox mandatory-select "
                      required
                      name="edu_type_sub_one"
                      value={formData?.edu_type_sub_one ?? ""}
                      aria-label="Default select example"
                      // onChange={(e) => selectFieldChange(e)}
                      onChange={(e) => handleEducationalChange(e)}
                    >
                      <option>-- Select --</option>
                      {qualSub?.map(
                        (item, i) =>
                          item?.name !== "Others" && (
                            <option value={item.name} key={i}>
                              {item.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              )}

              {formData?.edu_type_sub_one &&
                formData?.edu_type_sub_one !== "PHD" &&
                qualStream &&
                qualStream.length !== 0 && (
                  <div className="col-3">
                    <div className="col-xs-12 col-sm-12">
                      <div className="mb-1 ">Stream</div>
                      <select
                        className="form-select selectbox mandatory-select "
                        required
                        name="edu_type_sub_two"
                        value={formData?.edu_type_sub_two ?? ""}
                        aria-label="Default select example"
                        // onChange={(e) => selectFieldChange(e)}
                        onChange={(e) => handleEducationalChange(e)}
                      >
                        <option>-- Select --</option>
                        {qualStream?.map((item, i) => item.name !== "Others" &&(
                          <option value={item.name} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              {formData?.edu_type_sub_one &&
                qualStream &&
                qualStream.length !== 0 && (
                  <div className="col-2">
                    <div className="col-xs-12 col-sm-12">
                      <div className="mb-1 ">Percentage</div>
                      <select
                        className="form-select selectbox mandatory-select "
                        required
                        name="percentage"
                        value={formData?.percentage ?? ""}
                        aria-label="Default select example"
                        // onChange={(e) => selectFieldChange(e)}
                        onChange={(e) => handleEducationalChange(e)}
                      >
                        <option>-- Select --</option>
                        {percent?.map((item, i) => (
                          <option value={item.value} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

              <div className="col-1 ">
                <div className="h-100 d-flex align-items-end">
                  <SearchButton {...props} onClick={() => listData(param)} />
                </div>
              </div>
            </div>
            <div className="new-res-tile-cont-jb">
              {searchData.length !== 0 ? (
                <>
                  {searchData?.map((item) => (
                    <>
                      <ResourseTileCard
                        data={item}
                        {...props}
                        path={`/candidate-view/${item?.id}`}
                        name={item?.name.charAt(0)}
                        // photo={`${result}${item?.photo}`}
                        title={`${item?.prefix} . ${item?.name}`}
                        email={item?.email}
                        // qualification={`${item?.education &&
                        //   item?.education[0].edu_type_sub_one}`}
                        qualification = {`${setValue ? setValue : item?.education &&
                            item?.education[0].edu_type_sub_one}`}
                        interest={`${item?.interest_type}`}
                        // interest-type = {item.interest}
                        expIn={item?.area_of_expertise}
                        candidate={true}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="no-data">
                    <img src="resources/img/no1.png" alt="" />
                    <h4>No Records Found..!!</h4>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
