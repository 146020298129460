import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";

function PaymentModal(props) {
  const handleClose = () => {
    props.setPaymentOpen(false);
  };
  const handleAgree = () => {
    props.handleCompanyDownload()
    // window.open(props.pdf, "_blank");
    // props.setPdf("");
  };
  console.log("opened");
  return (
    <div className="payment-modal-parent">
      <Modal
        {...props}
        show={props.paymentOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="payment-modal">
          <Modal.Title id="contained-modal-title-vcenter">
            Profile Downloaded
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=""><table><tr><th></th></tr></table></div> */}
          <div className="">
            Thank you for the interest. Kindly update the status of recruitment
            in real-time. We expect to get a voluntary payment upon successful
            appointment of the candidate.
          </div>
          <br />
          <div>
            <table className="payment-table">
              <thead>
                <tr className="col-12">
                  <th className="col-6">Qualification Details</th>
                  <th className="col-6">Payment Expected (incl GST)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Below Graduation</td>
                  <td>₹500</td>
                </tr>
                <tr>
                  <td>Graduation</td>
                  <td>₹1000</td>
                </tr>
                <tr>
                  <td>Post-Graduation</td>
                  <td>₹2000</td>
                </tr>
                <tr>
                  <td>PHD</td>
                  <td>₹5000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleClose()}>Close</Button>
          <Button onClick={(e) => handleAgree()}>Agree</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentModal;
