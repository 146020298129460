import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import CheckBox from "../../../../../components/inputs/checkbox";
import TextField from "../../../../../components/inputs/textfield";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_tender_add, admin_tender_update } from "../../../admin.api";
import "./dialog.css";
import CommonForm from "../../../../../components/commonform/commonForm";
import DatePickerField from "../../../../../components/inputs/datepicker";
import moment from "moment";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
      fileError: false,
      fileChange: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  handleDatePickerChange = (e, name) => {
    let date = e.value;
    console.log(e.value)
    if (date) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: date,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: null,
        },
      });
    }
  };
  handleCheckChange = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;

    if (checked) {
      this.setState({
        formData: { ...this.state.formData, tender_publish_status: 1 },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, tender_publish_status: 0 },
      });
    }
  };
  handlePublicCheckChange = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;

    if (checked) {
      this.setState({
        formData: { ...this.state.formData, type: "public", public: 1 },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          type: "private",
          public: 0,
          file: null,
          file_name: null,
        },
      });
    }
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };

  handleSubmit = (e) => {
    const { edit, formData, fileError, fileChange } = this.state;
    console.log(formData);
    // const date = formData?.due_date ? moment(formData?.due_date).format("YYYY-MM-DD") : ""
    let required = [
      "tender_title",
      "organized_by",
      "value_of_tender",
      "description",
      ...(formData.link ? ["link"] : ["file", "file_name"]),
    ];
    const error = FormValidate(this.state.formData, required);
console.log(error)
    e.preventDefault();
    let payload = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "file" && fileChange) {
        if (formData.file) {
          payload.append("file", formData?.file);
        }
      } else if (key === "file_name") {
        if (formData.file) {
          payload.append("file_name", formData?.file_name);
        }
      } else if (key === "link") {
        if (formData.link) {
          payload.append("link", formData?.link);
        }
      } else if(key === "due_date") {
        // console.log(1)
        payload.append("due_date" ,  formData?.due_date ? moment(formData?.due_date, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm") : "")
      } else if(key !== "file") {
        
        payload.append(key, formData[key]);
      }
    });

    if (Object.keys(error).length === 0 && !fileError) {
      if (edit) {
        admin_tender_update(formData.id, payload)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_tender_add(payload)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleSelect = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        parent_id: e.target.value,
      },
    });
  };

  handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (
      type?.toLowerCase() === "pdf" ||
      type?.toLowerCase() === "doc" ||
      type?.toLowerCase() === "docx"
    ) {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        this.setState({ fileChange: true });
        this.setState({ fileError: false });
        this.setState({
          formData: {
            ...this.state.formData,
            file: e.target.files[0],
            file_name: e.target.files[0].name,
          },
        });
      } else {
        this.setState({ fileError: true });
        this.setState({
          formData: { ...this.state.formData, file: null, file_name: null },
        });
      }
    } else {
      this.setState({ fileError: true });
      this.setState({
        formData: { ...this.state.formData, file: null, file_name: null },
      });
    }
  };

  render() {
    console.log(this?.state?.formData);
    const { submitted, formData, open, edit } = this.state;
    const { view } = this.props;
    const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia-tender-admin">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit ? "Edit Tender" : view ? "Tender" : "Add Tender"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebars">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row book-form">
                      <div className="content-scroll-xx">
                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.tender_title ?? ""}
                            name="tender_title"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            label="Organized by"
                            name="organized_by"
                            variant="outlined"
                            disabled={view}
                            value={formData.organized_by ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>

                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            name="value_of_tender"
                            onChange={(e) => this.handleChange(e)}
                            label="Tender Value"
                            disabled={view}
                            value={formData.value_of_tender ?? ""}
                            variant="outlined"
                            type="number"
                            required
                          />
                        </div>

                        <div className="col-12">
                          <TextField
                            view="straight"
                            id="outlined-basic"
                            name="description"
                            onChange={(e) => this.handleChange(e)}
                            label="Description"
                            disabled={view}
                            value={formData.description ?? ""}
                            variant="outlined"
                            multiline
                            required
                          />
                           
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">Due Date</div>
                        <div className="col-9 mt-3">
                          
                        <DatePickerField
                      // required
                      value={ formData.due_date ?? undefined}
                      minDate={tomorrow}
                      disabled={view}
                      // label="Due Date"
                      // required
                      format = "DD-MM-YYYY HH:mm"
                     
                      plugins = {[
                        <TimePicker hideSeconds />
                      ]}
                      onChange={(e) =>
                        this.handleDatePickerChange(e, "due_date")
                      }
                    />
                        </div>
                        </div>

                        <div className=" row">
                          <div className="col-5 checkboxStatus w-50">
                            <CheckBox
                              id="outlined-basic"
                              name="tender_publish_status"
                              label="Enable"
                              onChange={(e) => this.handleCheckChange(e)}
                              disabled={view}
                              value={
                                formData.tender_publish_status === 1
                                  ? true
                                  : false
                              }
                              checked={
                                formData.tender_publish_status === 1
                                  ? true
                                  : false
                              }
                              variant="outlined"
                            />
                          </div>
                          <div className="col-5 checkboxStatus w-50">
                            <CheckBox
                              id="outlined-basic"
                              name="public"
                              label="Public"
                              onChange={(e) => this.handlePublicCheckChange(e)}
                              disabled={view}
                              value={
                                formData.public && formData.public === 1
                                  ? true
                                  : false
                              }
                              checked={formData.public === 1 ? true : false}
                              variant="outlined"
                            />
                          </div>
                        </div>
                    
                          <div className="col-12">
                            <TextField
                              view="straight"
                              id="outlined-basic"
                              name="link"
                              onChange={(e) => this.handleChange(e)}
                              label="Link"
                              disabled={view}
                              max="20"
                              value={formData.link ?? ""}
                              variant="outlined"
                              // required
                            />
                          </div>
                        
                        
                            <div className="col-12">
                              <TextField
                                type="file"
                                view="straight"
                                id="outlined-basic"
                                onChange={(e) => this.handleFileChange(e)}
                                label="File"
                                disabled={view}
                                fileName={formData.file_name}
                                accept=".pdf,.docx,.doc"
                                // required
                              />
                              {this.state.fileError && (
                                <div className="file-up-helper text-center">
                                  *Upload only .pdf , .doc, .docx and Size
                                  should be less than 1MB
                                </div>
                              )}
                            </div>
                         
                       
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
